import React from "react"
import SEO from "./SEO"
import { Location } from '@reach/router';
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'

function LayoutStatic(props) {
    return (
        <Location>
        {locationProps => (
            <>
            <SEO title={props.title} {...locationProps} ogp={props.ogp} description={props.description} />
            <SiteHeader {...locationProps} {...props} />
            <div id="postMain">
  		        <div id="postContainer">
                    {props.children}
        		</div>
 	        </div>
            <SiteFooter {...locationProps} {...props} />
            </>
        )}
        </Location>
    )
}

export default LayoutStatic
