import React from 'react'
import LayoutStatic from '../../components/LayoutStatic'
import { useIntl } from 'gatsby-plugin-intl'

function Privacy(props) {
    const intl = useIntl()
    return (
        <LayoutStatic {...props} title={intl.formatMessage({ id: 'privacy.title' })}>
        { intl.locale === 'ja' ? 
        <>
<h2 className="staticInfo">本アプリのプライバシーポリシー</h2>
<ol>
<li><h3>Google Analytics</h3><br/>
本アプリでは、サービスの改善を目的として、ユーザーがどのようにアプリを使用するかを分析するために「Google Analytics」を使用しています。<br/>
Google Analyticsは、端末の匿名IDや、ページのトラッキング情報などを収集し使用することがあります。<br/>
これらの情報に非匿名の情報は一切含まれませんが、収集されることを望まない場合は回避（オプトアウト）することが可能です。<br/>
オプトアウトするには、本アプリの設定画面で、Google Analytics - オプトアウトをオンにしてください。
</li>
<li><h3>個人情報の保護、管理等について</h3><br/>
当方は本アプリの開発やサービスの運営に際し、お客様のプライバシーを尊重し、個人情報に対して十分な配慮を行うとともに、それを適切に保護・管理することに努めております。また、必要な場合は改善を行います。
</li>
<li><h3>本プライバシーポリシーの改訂について</h3><br/>
本プライバシーポリシーは、本アプリのバージョン変更や、OSやその他の環境の変化などによって合理的な必要性が生じた場合、改訂されることがあります。
</li>
</ol>

<h2 className="staticInfo">本サイトのプライバシーポリシー</h2>
<ol>
<li><h3>個人情報利用目的</h3><br/>
お客様の個人情報（氏名、Eメールアドレス、ウェブサービスのアカウント名など）は、原則として、当サイトに関するお問い合わせへのご回答のために利用致します。<br/>
それ以外の目的で利用する場合は、個人情報をご提供いただく際にあらかじめ目的を明示します。
</li>
<li><h3>第三者への情報提供</h3><br/>
当方は、お客様の個人情報を、法的拘束力がある第三者機関からの開示要求があった場合を除き、事前にご本人の同意を得ることなく第三者に提供しません。
</li>
<li><h3>適用範囲</h3><br/>
本プライバシーポリシーは当サイト内にのみ適用されます。
</li>
<li><h3>Google Analytics</h3><br/>
当サイトでは、ユーザーがどのようにサイトを使用するかを分析するために「Google Analytics」を使用しています。<br/>
Google Analyticsは、インターネットの標準的なログ情報や訪問者の行動を匿名形式で収集するために、「クッキー」と呼ばれるコンピュータ上のテキストファイルを使用しています。<br/>
Google Analyticsによる情報収集を拒否したい場合は、Cookieを無効にするか、Google アナリティクス オプトアウト アドオンを使用してください。 https://tools.google.com/dlpage/gaoptout
</li>
<li><h3>プライバシーポリシーの責任範囲について</h3><br/>
当方は当サイトの運営に際し、お客様のプライバシーを尊重し、個人情報に対して十分な配慮を行うとともに、それを適切に保護・管理することに努めております。<br/>
しかし残念ながら、インターネット経由で100%安全なデータ送受信を行うことは不可能なため、その安全性を保証することはできません。本プライバシーポリシーにご同意できない場合は、誠に申し訳ございませんが当サイトのご利用をお控えください。<br/>
また、当方は本プライバシーポリシーを必要に応じて変更する権利を有します。
</li>
</ol>
</>
 :
<>
<h2 class="staticInfo">Privacy Policy of this app</h2>
<ol>
<li><h3>Google Analytics</h3><br/>
In this app, "Google Analytics" is used to analyze how users are utilizing the app in order to improve service.<br/>
Google Analytics may collect and use information such as the device's anonymous ID, tracking information of pages, etc.<br/>
These information does not include any identifiable personal information, but if you do not wish the information to be collected, you may choose to avoid (opt out) this.<br/>
In order to opt out, please set "Google Analytics" - "Opt Out" on this app's settings screen to ON.
</li>
<li><h3>Personal Information Protection and Management</h3><br/>
We respects the privacy of our customers regarding the development of this app and the operation of this service, and we exercise great care with the personal information of our users while working to protect and manage it appropriately. We also make improvements as needed.
</li>
<li><h3>Revisions to the Privacy Policy</h3><br/>
This privacy policy may be revised In the event of a reasonable necessity, such as version updates to this app or changes in the OS or other environment.
</li>
</ol>

<h2 class="staticInfo">Privacy Policy of this site</h2>
<ol>
<li><h3>Use of Personal Information</h3><br/>
Users' personal information (such as full name, email address, and account name) is, as a general rule, used only to respond to inquiries related to this site. In the event that your personal information is to be used in any other way, we will provide explicit notice in advance.
</li>
<li><h3>Use of Information by Third Parties</h3><br/>
Except in cases where provision of information to a third party is legally mandated, we will not release personal information without the prior consent of the user.
</li>
<li><h3>Scope of Privacy Policy</h3><br/>
This privacy policy applies only to this site.
</li>
<li><h3>Google Analytics</h3><br/>
This website uses "Google Analytics" to analyze how users use the site.<br/>
Google Analytics uses "Cookie" to collect standard Internet log information and visitor behavior information in an anonymous form.<br/>
If you want to deny analyses of Google Analytics, disable Cookie or use Google Analytics Opt-out Browser Add-on. https://tools.google.com/dlpage/gaoptout
</li>
<li><h3>Our Privacy Responsibilities</h3><br/>
In our management of this website, we respect users' privacy and thoroughly acknowledge the value of their personal information. To this end, we strive to implement any and all relevant measures for the protection and management of this data.<br/>
Unfortunately, because data transmitted over the Internet is never 100% secure, we cannot guarantee the security of your information.<br/>
Should you disagree with the terms of this privacy policy, we offer our profound apologies, but must ask that you refrain from using our site.<br/>
We reserve the right to make changes to this policy when necessary.
</li>
</ol>
</>
    }
        </LayoutStatic>
    )
}

export default Privacy
